#indexDownPane{
    margin: auto;
  
    .downpaneSlide{
        background-color: #fff;
        text-align: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: initial;
        width: 100%;
        height: 100%;
        margin: auto;
       
        .down-info{
            background: #2ea7e0;
            color: white;
            height: 100% !important;
            margin-bottom: 0 !important;
            margin-left: 0 !important;
            padding-left: 50px;
            padding-top: 50px;
            padding-bottom: 20px;
            .title-info{
                color: white;
                text-align: left;
                font-size: 2.5em;
            }
            .desc-info{
                color: white;
                text-align: left;
                text-align: justify;
                padding-right: 30px;
            }
            .down-btn-pane{
                text-align: left;
                margin-top: 40px;
                a{
                    display: inline-block;
                    background: white;
                    color: black;
                    padding: 10px 50px;
                    border-radius: 3px;
                }
            }
           .text-left{
               .videos_item{
                    .videos_link{
                        border: none;
                        border-radius: 3px;
                        box-sizing: border-box;
                        color: #fff;
                        padding: 0 0 0 57px;
                        position: relative;
                        height: 50px;
                        text-align: left;
                        width: 100%;
                        font-size: 1.2em !important;
                        background: transparent;
                        position: relative;
                        &::before{
                            content: "";
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            background: rgba(255,255,255,.2);
                            border-radius: 9px;
                            left: 20px;
                            top: 15px;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            background: #fff;
                            border-radius: 3px;
                            left: 26px;
                            top: 21px;
                        }
                    }
               }
           }
        }
        &.noneDown{
            display: inherit;
            .img-slideDown{
            }
            .down-info{
               padding: 20px 20px 20px 30px !important;
            }
            
        }
    }
}
