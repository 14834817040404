body {
    // font-family: 'Jura Medium' !important;
     font-family: verdana !important;
    &::-webkit-scrollbar {
      cursor: pointer;
      width: 5px;
      height: 10px;
      border-radius: 5px;
      background: #c4c4c4;
      transition: all 0.3s ease;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
      background: #e5e5e5;
      cursor: pointer;
    }
  }
  
  
  #myCarousel{
  
      @media (max-width: 992px){
            margin-top: 55px !important;
      }
      
    margin-top: 97px;
    
    .item-slider{
        cursor: pointer;
        img{
            margin: auto;
            width: 100%;
            height: 100%;
        }
    }
       
}
.layout_container{
    
    .ml-50{
        margin-left: -50% !important;
    }
    .mt55{
        margin-top: 55px !important;
    }
    
    .l-index{
        
      
        .slide-moble{
            min-width: 1200px;
        }
        .rs_downpane{
            padding: 0 50px;
            .down-info{
                margin-left: -50% !important;
               
            }
        }
       
       

      
        .responsive-register{
            .start-trading{
                display: block !important;
                box-shadow: none !important;
                width: 100% !important;
                background: #37bef0;
                
                .linknew{
                    color: white;
                    display: inline-block;
                    font-weight: bold;
                }
                .start-input{
                    width: 70% !important;
                    margin: auto !important;
                }
                .start-btn{
                    display: block !important;
                    margin: auto !important;
                    margin-top: 10px !important;
                }
            }
        }
        .index-register{
            padding: 0 50px;
            height: 468px;
            border-collapse: separate;
            text-align: center;
            h2{
                line-height: 40px;
                text-align: center;
                padding-top: 168px;
                padding-bottom: 40px;
                font-weight: 700;
                font-size: 30px;
                color: #1c2143;
                letter-spacing: .3px;
            }
            .start-trading{
                display: flex;
                align-items: center;
                width: 408px;
                height: 48px;
                margin: 0 auto;
                box-shadow: 0 2px 6px rgba(0,0,0,.09);
                vertical-align: top;
                background: #37bef0;
                .linknew{
                    color: white;
                    font-weight: bold;
                }
                .start-input{
                    position: relative;
                    display: inline-block;
                    width: 267px;
                    height: 48px;
                    border: 1px solid #e3e3e3;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    vertical-align: top;
                    box-sizing: border-box; 
                    .place-holder{
                        position: absolute;
                        display: inline-block;
                        top: 12px;
                        left: 12px;
                        height: 24px;
                        line-height: 24px;
                        color: #8c8c8c;
                        font-size: 18px;
                        z-index: 1;
                        letter-spacing: .21px;
                        &.focus{
                            height: 11px;
                            top: 1px;
                            font-size: 12px;
                        }
                    }
                    input{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 46px;
                        padding-left: 12px;
                        padding-top: 18px;
                        border: 0;
                        box-sizing: border-box;
                        background-color: transparent;
                        color: #1c2143;
                        font-size: 18px;
                        z-index: 2;
                        letter-spacing: .21px;
                    }
                }
                .start-btn{
                    display: inline-block;
                    width: 139px;
                    height: 48px;
                    line-height: 48px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    background-color: #37bef0;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                    transition: all .5s;
                    letter-spacing: .09px;
                    &:hover{
                        background-color: #229bc7
                    }
                }
            }
        }
    }
}
 .slick-slider{
        .slick-next{
            display: none !important;
            right: 8px;
        }
    }
    .list-promotion{
        display: none;
    }
    .mobile-home{
        @media (max-width: 992px){
            .list-promotion{
                display: block !important;
            }
        @media (max-width: 576px){
            .list-promotion{
                .content-l-pro{
                    text-align: center;
                    display: inherit !important;
                    width: 100% !important;
                    li{
                        width: 100% !important;
                        margin-right: 0 !important;
                        a{
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    } 
                }
            }
        }
    }
}
    