.header-main{
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    background: white;
    border-bottom: 1px solid #eae0e0;
    .top-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 50px;
        background: #f5f5f573;
        .notice-list{
            .item-list{
                display: inline-flex;
                width: 365px;
                height: 100%;
                padding: 0 5px;
                overflow: hidden;
                .text-list{
                    display: inline-block;
                    font-size: 12px;
                    color: rgba(28,33,67,.5);
                    white-space: nowrap;
                    padding-right: 2px;
                    font-weight: 500;
                    letter-spacing: .07px;
                }
                .link-list{
                    font-size: 14px;
                    text-align: left;
                    color: #1c2143;
                    text-decoration: underline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    letter-spacing: .1px;
                }
            }
        }
        .view-all-btn{
            display: inline-block;
            width: 100px;
            height: 26px;
            line-height: 24px;
            border-radius: 13px;
            border: 1px solid #1c2143;
            text-align: center;
            color: #1c2143;
            font-size: 12px;
            cursor: pointer;

            &:hover{
                border: 2px solid #1c2143;
                color: black;
                font-weight: 500;
            }
        }
    }
    .l-head{
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        .h-logo{
            .logo-head{
                display: inline-block;
                img{
                    display: inline-block;
                    //width: 250px;
                    height: 55px;
                }
            }
        }
        .nav-menu{
            display: flex;
            align-items: center;
            @media (max-width: 1200px){
                .item-menu{
                    font-size: 10px !important;
                    margin-left: 20px !important;
                }
            }
            .item-menu{
                margin-left: 20px !important;
                font-size: 1em;
                font-weight: 400;
                position: relative;
                &.activemenuPc{
                    a{
                        color: #3382d1 !important;
                    }
                }
                &:first-child{
                    margin-left: 0px;
                }
                .sub-menu{
                    min-width: 150px;
                    left: 0;
                    right: auto;
                    display: none;
                    position: absolute;
                    top: 47px;
                    background: #ffffff;
                    ul{
                        li{
                            line-height: 30px;
                            padding: 7px 10px;
                            
                            a{
                                border-bottom: 1px solid #ededed;
                                display: block;
                                font-size: 15px;
                                color: #000000;
                            }
                        }
                    }
                }
                &:hover{
                    .sub-menu{
                        display: block;
                        ul{
                            li{
                                &:hover{
                                    background: #ededed;
                                }
                               
                                
                            }
                        }
                    }
                }
               
                &:hover{
                    color: #76bbe6;
                }
                &.log-up{
                    position: relative;
                    margin-left: 24px;
                    height: 56px;
                    line-height: 68px;
                    white-space: nowrap;
                    a{
                        display: inline-block;
                        width: 97px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 3px;
                        background-color: #37bef0;
                        text-align: center;
                        color: #fff!important;
                        font-weight: 700;
                        font-size: 14px!important;
                        &:hover{
                            background: #49a8cc;
                        }
                    }
                }
            }
        }
    }
}

.responsive-header{
    .left-head{
        a{
            display: inline-block;
            img{
                display: inline-block;      
                // width: 250px;
                height: auto;
            }
        }
    }
    .right-head{
        span{
            padding: 7px 13px;
            background: #13426b;
            border-radius: 5px;
            cursor: pointer;
            i{
                font-size: 17px;
                color: white;
            }
        }
    }
    .menu-mini{
        .content-menu{
            padding: 10px;
            > div{
                box-shadow: inherit;
            }
            .title-menu-mini{
                min-height: fit-content;
                > div{
                    margin: 0;
                }
                border: none;
                padding: 0px 15px;
                box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                
                &:hover{
                    background: #1c2143;
                    a{
                        color: white;
                    }
                }
                &:last-child{
                    border-bottom: none;
                }
                a{
                    width: 100%;
                    text-shadow: 0 1px 0 rgba(0,0,0,0.1);
                    font-size: 15px;
                    display: block;
                    padding: 10px 0;
                    font-weight: 500;
                }
            }
            .sub-menu-mini{
                background: gainsboro;
                padding: 10px 15px;
            }
        }
    }
}