.bar-link{
    width: 100%;
    background: #4276cd;
    .content-bar-link{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding: 10px 0 ;
        width: 90%;
        margin: auto;
        .item-bar-link{
            .text-item-bar{
                font-size: 14px;
                color: white;}
                margin-right: 0 !important;
                text-transform: uppercase !important;
                padding: 5px !important;
                border-bottom: 2px solid #ffffff61 !important;
                &:last-child{
                    border-bottom: none !important; 
                }
                &:nth-child(3){
                    border-bottom: none !important; 
                }
               
                p{
                    font-size: 14px !important;
                    span{
                        display: none;
                    }
                }
        }
       
        @media (min-width: 768px) {
            padding: 15px 0;
            display: flex !important;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin: auto;
            .item-bar-link{
                font-family: 'Jura Medium' !important;
                margin-right: 50px;
                border-bottom: none !important; 
                .text-item-bar{
                    font-size: 16px !important;
                    span{
                        display: inline-block !important;
                        margin-left: 5px;
                        i{
                            color: orange;
                        }
                    }
                }
            }
        }
        @media(min-width: 1200px){
            .text-item-bar{
                font-size: 19px !important;
            }
        }
       
    }
}