.custom-modal{
    .custom-modal-header{
        font-size: 15px;
        font-weight: bold;
        border: none;

        .close-bt{
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    
    .custom-modal-content{
        border: none;
    }

    .custom-modal-actions{
        margin-top: 20px;
        width: 100%;
        text-align: right;

        button{
            font-size: 15px !important;
        }
    }
}

@keyframes active {
    from {width: 0px}
    to {width: 100%;}
}
@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes expand{
    from {height: 0px;}
    to {height: 30px;}
}