@charset "utf-8";
body{
  background: white;
  font-size: 15px;
}
.notify{
  padding: 5px;
  width: 100%;
  display: inline-block;
  &.error{
    background: rgb(255, 237, 237);
    color: rgb(209, 91, 91);
    margin-top: 5px;
  }
}
span.empty-mes {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #ccc;
}
.clear-fix:before, .clear-fix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden
}
.clear-fix:after {
  clear: both
}
.clear-fix {
  zoom: 1
}
.text-green{
  color: green;
}
.text-red{
  color: red;
}
.text-black{
  color: black;
}
ul,li{
  list-style: none;
  padding: 0px;
  margin: 0px;
}
td.border-none{
  border: none !important;
}
p{
  padding: 0px;
  margin: 0px;
}
.bg-orange{
  background: #f3ba2e !important;
  color: white !important;
}
.pdtop80px{
  padding-top: 80px;
}
a{
  text-decoration: none;
  color: black;
  &:hover{
    color: black;
    text-decoration: none;
  }
}
h1,h2,h3,h4,h5{
  padding: 0;
  margin: 0;
}

.pd-width15px{
  padding: 0 15px;
}
.bgr-while{
  background-color: white;
}
.fontsize16px{
  font-size: 16px
}
.marginauto{
  margin: auto;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.mr20{
  margin: 0 20px;
}
.pd-height20px{
  padding: 20px 0;
}
.background-none{
  background: none !important;
}
.mrtop20px{
  margin-top: 20px;
}
.pdwidth20px{
  padding: 0 15px;
}
