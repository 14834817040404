.footer{
 
    .flying_footer{
        background: hsl(0, 0%, 100%);
        border-top: 1px solid #ccc;
        display: block;
        width: 100%;
        height: 50px;
        bottom: 0;
        z-index: 110;
        text-align: center;
        transition: height .2s linear;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            .list-icon{
                height: 50px;
                display: flex;
                align-items: center;
                li{
                    &:hover{
                        color: #fff;
                        background: #2054ae;
                    }
                    &.chat{
                        border-right: 1px solid #ccc;
                    }
                    list-style: none;
                    float: left;
                    color: #2054ae;
                    font-size: 115%;
                    padding: 0 23px;
                    cursor: pointer;
                    line-height: 50px;
                    transition: all .3s linear;
                    border-left: 1px solid #ccc;
                    
                    &.phone{
                        &.active{
                            color: #fff;
                            background: #2054ae;
                            .phone-box{
                                left: 10px;
                                
                            }
                        }
                        .phone-box{
                            left: -1000px;
                            background-color: #e4e4e4;
                            width: 23em;
                            @media (max-width: 576px) {
                                width: 95%;
                            }
                            padding: 1em 1.4em;
                            transition: left .8s;
                            position: fixed;
                            bottom: 60px;
                            z-index: 10000;
                            overflow: hidden;
                            text-align: left;
                            border-radius: 5px;
                            border: 1px solid #ccc;
                            cursor: default;
                            .title{
                                font-size: 200%;
                                color: #4c77c7;
                                line-height: normal;
                                font-weight: 100;
                            }
                            .message {
                                margin-top: 10px;
                                color: #666;
                                font-size: 15px;
                                line-height: normal;
                                padding-left: 3px;
                            }
                            .contact{
                                .contact-item{
                                    height: 30px;
                                    span{
                                        display: inline-block;
                                        font-weight: 100;
                                        line-height: 30px;
                                        padding: 0 10px;
                                        box-sizing: border-box;
                                        color: #4c77c7;
                                        &.name{
                                            width: 35%;
                                            text-align: right;
                                            font-size: 80%;
                                        }
                                        &.number{
                                            width: 65%;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .right{
            display: inline-block;
            vertical-align: top;
            width: fit-content;
            span{
                line-height: 50px;
                font-size: 130%;
                color: #2054ae;
                margin-right: 20px;
            }
            a{
                text-decoration: none;
                background: #62bc33;
                border-radius: 5px;
                color: #fff;
                font-size: 18px;
                padding: 8px;
                @media(max-width: 576px){
                    font-size: 12px;
                    padding: 8px;
                }
            }
        }
    }
}

.responsive-footer{
  
    .flying_footer{
        position: fixed !important;
        // @media (max-width: 576px){
        //     .left{
        //         float: left !important;
        //         margin-left: 10%;
        //         height: 100%;
        //         .list-icon{
        //             height: 100%;
        //         }
        //     }
        //     .right{
        //         float: left;
        //         margin-left: 20%;
        //         width: auto !important;
        //     }
        // }
        .left{
            width: 135px;
            .list-icon{
                padding: 0;
                
            }
        }
        .right{
            font-size: 18px;
            text-align: center;
            min-width: 0;
            span{
                @media (max-width: 576px){
                    display: block !important;
                }
                
                // .title{
                //     line-height: 25px;
                //     display: inline-block;
                //     width: 100%;
                // }
            }
        }
    }
    #featured-section{
        .content-featured{
            .item-featured{
                width: 45%;
                margin-right: 5%;
            }
        }
    }
}

#featured-section{
    padding-top: 30px;
    padding-bottom: 10px;
    .content-featured{
        padding: 0 10px;
        @media(min-width: 768px){
            width: 90%;
            max-width: 90%;
            margin: auto;
        }
        .item-featured{
            float: left;
            width: 22%;
            margin-right: 3%;
            &:last-child{
               a{
                border-right: none;
               }
            }
            a{
                display: block;
                text-align: center;
                margin-bottom: 6%;
                border-right: 1px solid #c1c0c296;
                .box-img{
                    height: 140px;
                    position: relative;
                    margin: 0 auto;
                    .img-item{
                        position: absolute;
                        width: auto;
                        height: auto;
                        max-width: 90%;
                        max-height: 130px;
                        margin: auto;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
                
                .text-title{
                    
                    font-size: 12px;
                    font-weight: 600;
                }
            }
            .text-detail{
                color: gray;
                font-size: 1em;
                text-align: center;
            }
        }
    }
}
#comment-star{
    background-color: #0287cc!important;

    .content-slide{
        padding: 32px 0;
        margin-left: auto!important;
        margin-right: auto!important;
        width: 90%;
        max-width: 90%;
        .title{
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            color: white;
            margin-bottom: 2.75%;
        }
        .slide-star{
            .slick-prev{
                display: block !important;
                &::before{
                    color: white;
                    
                }
            }
            .slick-next{
                display: block !important;
                &::before{
                    color: white;
                    right: -25px !important;
                }
            }
            .slick-dots{
                display: none !important;
            }
            .testimonial-inner{
               padding: 7px;
                .testimonial-client{
                    text-align: center;
                     padding: 20px;
                     background: #ffffff;
                    border-radius: .4em;
                    text-align: center;
                    
                    .strong-rating-wrapper{
                        margin-top: 20px;
                        padding: 5px;
                        .star{
                            i{
                                color: #f37721;
                                margin-right: 5px;
                                font-size: 17px;
                            }
                        }
                    }
                    .testimonial-content{
                        color: #0287cc;
                    }
                    .testimonial-name{
                        color: #0287cc;
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.thump{
    background-color: #ec7d30!important;
    .content-thumb{
        width: 90%;
        margin-left: auto!important;
        margin-right: auto!important;
        padding-top: 10px;
        padding-bottom: 10px;
        // img{
        //     width: 100%;
        // }
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 20px !important;
        color: white;
        font-size: 15px;
        li{
            font-style: italic;
            i{
            
                font-size: 30px;
                margin-left: 8px;
            }
        }
        @media (max-width: 992px){
            font-size: 14px !important;
            li{
                i{
                    font-size: 15px !important;
                }
            }
        }
        @media (max-width: 576px){
            font-size: 12px !important;
            li{
                i{
                    font-size: 10px !important;
                    margin-left: 5px !important;
                }
            }
        }
    }
}

