.contact-us{
    margin-top: 55px;
    @media(min-width: 992px){
        // margin-top: 55px;
    }
    .background-contact{
        background: url('../../assets/images/liên-he-2.jpg') no-repeat;
        width: 100%;
        height: 350px;
        background-size: cover;
        background-position: center;
    }
    .box-content{
        width: 87%;
        margin: auto;
        margin-top: 30px;
        .address{
            font-size: 1.2em;
        }
        .custom-input .text-danger.show{
            background: white;
        }
        .custom-input .text-danger.show > pre{
            background: transparent;
            color: red;
        }
        .custom-input > div.edit::after{
            border-bottom: none !important;
            height: 0;
        }
        textarea{
            border-radius: 3px;
        }
        .custom-form-control{
            height: 100% !important;
            line-height: 100% !important;
            padding: 3px 3px !important;
            margin-bottom: 15px;
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
         }
        .box-form-input{
            label{
                font-weight: 500;
            }
            .custom-input {
                > div {
                    > div {
                         i{
                            display: none !important;
                        }
                    }
                }
            }
        }
        .text-span{
            font-size: .9em;
            span{
                color: royalblue;
            }
        }
    }
    
   
}
