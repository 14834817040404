.index-about{
    @media (max-width: 576px){
        padding: 15px !important;
    }
    padding: 30px;
    background-color: white;
    text-align: center;
    .title-index{
        width: 80%;
        margin: auto;
        h2{
            color: #134597;
            padding-bottom: 20px;
        }
        p{
            color: gray;
            width: 60%;
            margin: auto;
        }
    }
    .content-about{
        padding: 15px 0;
        .about-item{
            margin-top: 20px;
            box-shadow: 0 2px 10px -5px;
            margin-right: 20px;
            display: inline-block;
            font-size: 20px;
            color: black;
            vertical-align: top;
            width: 225px;
            padding: 0 10px;
            
            &:last-child{
                margin-right: 0;
            }
            .title-item{
                height: 42px;
                line-height: 42px;
                font-weight: 700;
                letter-spacing: 1px;
                color: #2ea7e0;
                text-align: left;
                font-size: 18px;
            }
            
            .img-item{
                position: relative;
                padding: 10px 0;
                .content-img{
                    height: 150px;
                    img{
                        position: absolute;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 130px;
                        margin: auto;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
           
            .desc-item{
                width: 324px;
                text-align: left;
                letter-spacing: .17px;
                color: #808080;
                font-size: 14px;
                width: 100%;
            }
            .btn-item{
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
                span{
                    background: #2ea7e0;
                    color: white;
                    width: 150px;
                }
            }
        }
    }
}
#indexAbout{
    .item-indexAbout1{
        .about-item{
            width: 100% !important;
            margin-right: 0 !important;
            .title-item{
                height: 42px;
                line-height: 42px;
                font-weight: 700;
                letter-spacing: 1px;
                color: #2ea7e0;
                text-align: center;
                font-size: 18px;
            }
            .desc-item{
                width: 324px;
                text-align: center;
                letter-spacing: .17px;
                color: #808080;
                font-size: 14px;
                width: 100%;
            }
            .btn-item{
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
                span{
                    background: #2ea7e0;
                    color: white;
                    width: 100% !important;
                }
            }
            .img-item{
                position: relative;
                padding: 10px 0;
                .content-img{
                    height: 310px;
                    @media (max-width: 768px){
                        height: 170px !important;
                    }
                    img{
                        position: absolute;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        @media (max-width: 768px){
                            max-height: 170px !important;
                        }
                        max-height: 300px;
                        margin: auto;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
 
}
