.list-promotion{
    background: #e6e7e8;
    padding-top: 10px;
    .content-l-pro{
        display: flex;
        width: 95%;
        margin: auto;
       
        li{
            &:last-child{
                margin-right: 0;
            }
            width: 48.5%;
            margin-right: 3%;
            margin-bottom: 10px;
            
            a{
                display: inline-block;
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}