.components-pc{
    
    .my-activities{
        min-height: calc(100vh - 100px);
        margin-top: 100px;
        padding-top: 20px;
        padding-bottom: 50px;
        .my-activities-nav{
            background-color: #f2f2f2;
            border: 0;
            margin-bottom: 20px;
            .collapsible-nav-list{
                flex-direction: row;
                display: flex;
                padding: 0;
                li{
                    list-style: none;
                    margin-right: 30px;
                    a{
                        padding: 15px 0;
                        display: block;
                        position: relative;
                        &:hover{
                            &:after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 5px;
                                background: #dddddd;
                                bottom: 0;
                                left: 0;
                            }
                        }
                        &.active{
                            &:after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 5px;
                                background: #FAC863;
                                bottom: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .my-activities-sub-nav{
            border-bottom: 1px solid #ddd;
            margin-bottom: 20px;
            .collapsible-nav-list{
                flex-direction: row;
                display: flex;
                li{
                    margin-right: 30px;
                    a{
                        padding: 15px 0;
                        display: block;
                        position: relative;
                        &:hover{
                            &:after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 5px;
                                background: #dddddd;
                                bottom: 0;
                                left: 0;
                            }
                        }
                        &.active{
                            &:after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 5px;
                                background: #FAC863;
                                bottom: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .list-requests{
            .table{
                a{
                    color: #FAC863;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        .request-container{
            .collapsible-sidebar-title{
                margin-bottom: 15px;
            }
            .request-main-page{
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .request-main{
                width: calc(100% - 400px);
                padding-right: 20px;
                form{
                    width: 100%;
                }
                .comment-list{
                    padding: 0;
                    li{
                        list-style: none;
                    }
                }
                .comment {
                    border-bottom: 1px solid #ddd;
                    padding: 20px 10px;
                }

                .reply {
                    border-bottom: 1px solid #ddd;
                    padding: 20px 10px;
                    background-color: rgba(250, 200, 99, 0.1);
                }

                .comment-heading {
                    margin-bottom: 5px;
                    margin-top: 0;
                }

                .comment-overview {
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    padding: 20px 0;
                }

                .comment-overview p {
                    margin-top: 0;
                }

                .comment-callout {
                    color: #666;
                    display: inline-block;
                    font-weight: 300;
                    font-size: 13px;
                    margin-bottom: 0;
                }

                .comment-callout a {
                    color: #f3ba2e;
                }

                .comment-sorter {
                    display: inline-block;
                    float: right;
                }

                .comment-sorter a {
                    color: #666;
                    font-weight: 300;
                    font-size: 13px;
                    text-decoration: none;
                }

                [dir="rtl"] .comment-sorter {
                    float: left;
                }

                .comment-wrapper {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                }

                .comment-wrapper.comment-official {
                    border: 1px solid #f3ba2e;
                    padding: 40px 20px 20px;
                }

                @media (min-width: 768px) {
                    .comment-wrapper.comment-official {
                        padding-top: 20px;
                    }
                }

                .comment-info {
                    min-width: 0;
                    width: 100%;
                }

                [dir="rtl"] .comment-info {
                    padding-right: 0;
                    padding-left: 20px;
                }

                .comment-author {
                    align-items: flex-start;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    .comment-meta{
                        ul{
                            padding: 0;
                            li{
                                list-style: none;
                            }
                        }
                    }
                }

                @media (min-width: 768px) {
                    .comment-author {
                        justify-content: space-between;
                    }
                }

                .comment-avatar {
                    margin-right: 10px;
                }

                [dir="rtl"] .comment-avatar {
                    margin-left: 10px;
                    margin-right: 0;
                }

                .comment-meta {
                    flex: 1 0 auto;
                    width: 1px;
                }

                .comment-labels {
                    flex-basis: 100%;
                }

                @media (min-width: 768px) {
                    .comment-labels {
                        flex-basis: auto;
                    }
                }

                .comment .status-label:not(.status-label-official) {
                    margin-top: 10px;
                }

                @media (min-width: 768px) {
                    .comment .status-label:not(.status-label-official) {
                        margin-top: 0;
                    }
                }

                .comment-form {
                    display: flex;
                    padding-top: 30px;
                    word-wrap: break-word;
                }

                .comment-container {
                    width: 100%;
                }

                .comment-form-controls {
                    display: none;
                    margin-top: 10px;
                    text-align: left;
                }

                @media (min-width: 768px) {
                    [dir="ltr"] .comment-form-controls {
                        text-align: right;
                    }
                }

                .comment-form-controls input[type="submit"] {
                    margin-top: 15px;
                }

                @media (min-width: 1024px) {
                    .comment-form-controls input[type="submit"] {
                        margin-left: 15px;
                    }
                    [dir="rtl"] .comment-form-controls input[type="submit"] {
                        margin-left: 0;
                        margin-right: 15px;
                    }
                }

                .comment-form-controls input[type="checkbox"] {
                    margin-right: 5px;
                }

                .comment-form-controls input[type="checkbox"] [dir="rtl"] {
                    margin-left: 5px;
                }

                .comment-ccs {
                    display: none;
                }

                .comment-ccs + textarea {
                    margin-top: 10px;
                }

                .comment-attachments {
                    margin-top: 10px;
                }

                .comment-attachments a {
                    color: #f3ba2e;
                }

                .comment-body {
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                    -webkit-hyphens: auto;
                    word-break: break-word;
                    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                    line-height: 1.6;
                }

                .comment-body img {
                    height: auto;
                    max-width: 100%;
                }

                .comment-body ul, .comment-body ol {
                    padding-left: 20px;
                    list-style-position: outside;
                    margin: 20px 0 20px 20px;
                }

                [dir="rtl"] .comment-body ul, [dir="rtl"] .comment-body ol {
                    padding-right: 20px;
                    padding-left: 0;
                    margin-left: 0;
                    margin-right: 20px;
                }

                .comment-body ul > ul, .comment-body ol > ol, .comment-body ol > ul, .comment-body ul > ol {
                    margin: 0;
                }

                .comment-body ul {
                    list-style-type: disc;
                }

                .comment-body a:visited {
                    color: #b1810a;
                }

                .comment-body code {
                    background: #f7f7f7;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 0 5px;
                    margin: 0 2px;
                }

                .comment-body pre {
                    background: #f7f7f7;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 10px 15px;
                    overflow: auto;
                    white-space: pre;
                }

                .comment-body blockquote {
                    border-left: 1px solid #ddd;
                    color: #666;
                    font-style: italic;
                    padding: 0 15px;
                }

                .comment-mark-as-solved {
                    display: inline-block;
                }

                .avatar-comment img {
                    height: 40px;
                    width: 40px;
                }
                .user-avatar-comment {
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .request-sidebar{
                background-color: #f7f7f7;
                border: 0;
                font-size: 13px;
                flex: 0 0 auto;
                padding: 20px 20px;
                width: 400px;
                .request-details {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    margin-bottom: 20px;
                    tr{
                        td{
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                        td:nth-child(1){
                            font-weight: bold;
                        }
                        td:nth-child(2){
                            padding-left: 20px;
                            width: 55%;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}