.footer{
    .footer-content{
        .nav-info{
            background-color: #4276cd;
          
            .site-info{
                padding-top: 45px;
                .footer-info{
                    img{
                        width: 250px;
                        vertical-align: middle;
                    }
                }
                .logo-info{
                    padding-left: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 2px;
                }
                .contact-us{
                    padding-left: 10px;
                    margin-top: 24px;
                    letter-spacing: .2px;
                    h3{
                        font-size: 14px;
                    }
                    a{
                        text-decoration: underline;
                    }
                }
                .corp-address{
                    padding-left: 10px;
                    margin-top: 24px;
                    letter-spacing: .2px;
                    p{
                        height: 22px;
                        line-height: 22px;
                    }
                }
                .site-inc{
                    padding-left: 10px;
                    margin-top: 32px;
                    letter-spacing: .5px;
                }
            }
            .nav-items{ 
                text-align: left;
                min-height: 100%;
                margin: 0;
                display: flex;
                justify-content: center;
                .item-fc{
                    padding: 20px 50px;
                    border-left: 1px solid #5482c1;
                    .nav-title{
                        font-size: 17px;
                        font-weight: 100;
                        color: #ffffff;
                    }
                    .nav-text{
                        margin-top: 16px;
                        .ctt-text{
                            margin-bottom: 16px;
                            a{
                                color: #b3d2ff;
                                font-weight: 100;
                                &:hover{
                                    color: #b3d2ff;
                                    text-decoration: underline;
                                }
                            }
                        }
                        
                        
                    }
                }
               
            }
        }
        .site-desc{
            background: #4276cd;
            width: 100%;
            margin: 0!important;
            padding-bottom: 55px !important;
            border-top: 1px solid #5482c1;
            .wrapper-left{
                display: inline-block;
                width: 100%;
                text-align: center;
                ul{
                    margin: 0;
                    padding: 20px 0;
                    box-sizing: border-box;
                    li{
                        list-style: none;
                        display: inline-block;
                        font-size: 1.7em;
                        padding: 0 10px;
                        a{
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
            .wrapper-right{
                display: inline-block;
                width: 50%;
                text-align: center;
                .copy-right{
                    color: #fff;
                    font-weight: 100;
                }
            }
        }
    }
}

.responsive-footer{
   
    .footer-content{
        .nav-info{
            .nav-items{
                display: inherit;
                padding-top: 20px;
                .item-fc{
                    padding-bottom: 16px;
                    width: 100%;
                    padding: 20px !important;
                    margin-right: 0;
                    border: none;
                    .nav-title{
                        cursor: pointer;
                        padding: 10px 20px;
                        border: 1px solid #8cb0f1;
                        border-radius: 5px;
                    }
                    .nav-text{
                        display: none !important;
                    }
                    &.active-sub{
                        .nav-text{
                            display: block !important;
                            padding: 0 20px;
                            
                        }
                    }
                }
            }
        }
        .site-desc{
            padding-bottom: 70px;
            .wrapper-left{
                width: 100%;
            }
            .wrapper-right{
                width: 100%;
            }
        }
    }
    #featured-section{
        .content-featured{
            .item-featured{
                width: 45%;
                margin-right: 5%;
            }
        }
    }
}

#featured-section{
    padding-top: 30px;
    padding-bottom: 10px;
    .content-featured{
        width: 90%;
        max-width: 90%;
        margin: auto;
        .item-featured{
            float: left;
            width: 22%;
            margin-right: 3%;
            padding-bottom: 20px;
            &:nth-child(2n){
               a{
                border-right: none;
               }
            }
            a{
                display: block;
                text-align: center;
                margin-bottom: 6%;
                border-right: 1px solid #c1c0c296;
                
                .img-item{
                    display: block;
                    width: 80px;
                    margin: auto;
                }
                .text-title{
                    
                    font-size: 12px;
                    font-weight: 600;
                }
            }
            .text-detail{
                color: gray;
                font-size: 1em;
                text-align: center;
            }
        }
    }
}
#comment-star{
    background-color: #0287cc!important;

    .content-slide{
        padding: 32px 0;
        margin-left: auto!important;
        margin-right: auto!important;
        width: 90%;
        max-width: 90%;
        .title{
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            color: white;
            margin-bottom: 2.75%;
        }
        .slide-star{
            .slick-prev{
                display: block !important;
                &::before{
                    color: white;
                    
                }
            }
            .slick-next{
                display: block !important;
                &::before{
                    color: white;
                    right: -25px !important;
                }
            }
            .slick-dots{
                display: none !important;
            }
            .testimonial-inner{
               padding: 7px;
                .testimonial-client{
                    text-align: center;
                     padding: 20px;
                     background: #ffffff;
                    border-radius: .4em;
                    text-align: center;
                    
                    .strong-rating-wrapper{
                        margin-top: 20px;
                        padding: 5px;
                        .star{
                            i{
                                color: #f37721;
                                margin-right: 5px;
                                font-size: 17px;
                            }
                        }
                    }
                    .testimonial-content{
                        color: #0287cc;
                    }
                    .testimonial-name{
                        color: #0287cc;
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.thump{
    
    background-color: #5b585a!important;
    .content-thumb{
        width: 90%;
        margin-left: auto!important;
        margin-right: auto!important;
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            width: 100%;
        }
    }
}