.custom-input > div.edit::after, .custom-input > div.error::after{
    width: 98% !important;
    right: 0 !important;
    margin: auto !important;
}

// .isDisable{
//     &:disabled{

//     }
// }
@media (max-width: 992px) {
    .addClass{
        display: block !important;
    }
}
.addClass{
    display: none;
}
.credit-card{
   
    @media (max-width: 992px) {
        margin-top: 55px !important;
       
    }
    @media (max-width: 1300px) {
        // margin-top: 118px;
        margin-top: 57px;
    }
    display: flex;
    justify-content: center;
    margin-top: 57px;
    background: #8080804a;
    .credit-left{
        @media (max-width: 992px) {
            display: none !important;
        }
        .content-left{
            .poster{
                padding-bottom: 10px;
                padding-top: 10px;
                width: 250px;
                height: auto;
                display: block;
            }
        }
       
    }
    .credit-center{
        margin: 0 20px;
        width: 650px;
        background: #eef5fb;
        // background: url('../../../assets/images/1.jpg');
        // background: url('../../../assets/images/picturemessage_hcriozaq.1vh.png');
        background-position: center;
        padding: 10px 20px;
        @media (max-width: 992px) {
            width: 100% !important;
            margin: 0 !important;
        }
        .form-center{
            .bacground-text{
                color: #ff8d2b !important;
                float: left;
                width: 55%;
                font-size: 23px;
                font-family: 'Jura Medium' !important;
                                color: black;
                @media(min-width: 992px){
                    font-size: 18px;
                }
                @media(min-width: 1200px){
                    font-size: 23px;
                }
                .title1{
                    font-size: 2em;
                }
                .tt2{
                    font-size: 1.5em;
                }
                .tt3{
                    font-size: 2em;
                }
                .tt4{
                    font-size: 3.5em;
                    padding-left: .3em;
                    font-family: 'Jura Medium' !important;                }
                .tt5{
                    font-size: 1.5em;
                    font-family: 'Jura Medium' !important;
                                        padding-left: .6em;
                }
                .tt6{
                    font-size: 1.7em;
                    padding-left: .3em;
                    font-weight: 600;
                    span{
                        font-size: .8em;
                        font-weight: initial;
                        display: block;
                        padding-left: 3em;
                    }
                }
            }
            .content-form{
                float: right;
                width: 45%;
                .text{
                    margin-bottom: 25px;
                    width: 280px;
                    margin-top: 8px;
                    input{
                        width: 100%;
                        text-align: left ;
                        padding: 7px 10px;
                        letter-spacing: normal;
                        &::placeholder{
                            color: #bfbcbcbd;
                            text-align: center;
                        }
                    }
                }
                // .check-box{
                //     margin: 20px 0;
                //     text-align: right;
                //     span{
                //         color: white;
                //         font-size: 12px;
                //     }
                // }
                // .option-price{
                //     background: white;
                //     margin: 20px 0;
                //     width: 280px;
                //     padding: 7px 4px;
                // }
                .input-price{
                    color: black;
                    margin-bottom: 10px;
                    margin-top: 7px;
                    input{
                        padding: 3px 25px !important;
                    }
                }
                .dola-Current{
                    top: 8px !important;
                }
                .pp{
                    position: relative;
                    .dola-p{
                        position: absolute;
                        left: 12px;
                        top: 16px;
                    }
                }
                .option-YN{
                    .title-check{
                        color: black;
                        text-align: left;
                        font-size: .9em;
                    }
                   
                    span{
                        
                        color: black;
                        &.title-check{
                            display: block;
                        }
                    }
                    .ctys{
                        margin: 10px 0;
                        text-align: center;
                        .opys{
                            cursor: pointer;
                            background: #808080b0;
                            &.active{
                                background: radial-gradient(circle at center, #ffa150 0%, #ff7701 100%) !important;
                            }
                            color: white;
                            padding: 5px 20px;
                            margin-right: 20px;
                        }
                    }
                  
                }
                .title-option{
                    font-size: .9em !important;
                }
                .submit{
                    margin-top: 20px;
                    button{
                        width: 100%;
                        background: #f66816;
                        color: white;
                        font-weight: 600;
                        border: 2px solid white;
                        border-radius: inherit;
                        letter-spacing: 3px;
                        @media (min-width: 576px) {
                            font-size: 12px !important;
                        }
                        @media (min-width: 992px) {
                            font-size: 9px !important;
                        }
                        @media (min-width: 1200px) {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
}

#indexCard{
    .slick-prev{
        display: none !important;
    }
    .slick-dots{
        bottom: -5px !important; 
    }
}
.credit-mobile{
    .content-credit-mobile{
        margin: auto;
        color: white;
        .hotline{
            background-color: #0187cc;
            .text_inner{
                text-align: center;
                h2{
                    font-size: 1.3em;
                    padding-top: 20px;
                }
            }

        }
        .body-credit{
            padding-bottom: 0px!important;
            margin-bottom: 0px!important;
            .img-top-body{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .et_pb_text_inner{
                background: #e6e7e8 !important;
                padding: 10px 30px;
                font-size: 15px;
                .home-quote_wrapper{
                    .item-info{
                        .input-form{
                            .custom-form-control{
                                input{
                                    font-size: 17px !important;
                                    height: 42px !important;
                                }
                            }
                        }
                    }
                    .text{
                        margin-bottom: 15px;
                        input{
                            width: 100%;
                            height: 40px !important;
                            padding: 8px 10px;
                            border-radius: 2px;
                            text-align: left;
                            color: black;
                             &::placeholder{
                                 color: #bfbcbcbd;
                             }
                        }
                    }
                    .title-option{
                        font-size: 1.1em;
                    }
                    .option-YN{
                        span{
                            font-size: 1.1em;
                            color: black;
                            &.title-check{
                                display: block;
                            }
                        }
                        .ctys{
                            margin: 10px 0;
                            text-align: center;
                            .opys{
                                cursor: pointer;
                                background: #808080b0;
                                &.active{
                                    background: #0187cc;
                                }
                                color: white;
                                padding: 5px 20px;
                                margin-right: 20px;
                            }
                        }
                      
                    }
                    .input-current{
                        input{
                            padding: 3px 35px;
                        }
                    }
                    .dola-Current{
                        position: absolute;
                        color: black;
                        left: 20px;
                    }
                    .input-price{
                        color: black;
                        margin-bottom: 10px;
                        margin-top: 7px;
                        input{
                            padding: 3px 25px !important;
                        }
                    }
                    .dola-Current{
                        top: 8px !important;
                    }
                    // .check-box{
                    //     margin-bottom: 10px;
                    //     text-align: left;
                    //     color: black;
                    //     .title-check{
                    //         display: block;
                            
                    //     }
                    // }
                    .input-price{
                        color: black;
                        border-radius: 2px;
                        width: 100%;
                        padding: 7px 10px;
                        margin-bottom: 10px;
                        margin-top: 7px;
                    }
                }
                .option-industry{
                    .selection_group{
                        padding: 9px !important;
                        font-size: 17px !important;
                    }
                }
                .btn-submit{
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    font-size: 14px !important;
                  
                    span{
                        background: radial-gradient(circle at center,#ffa150 0%,#ff7701 100%)!important;
                        border: 1px solid #f05a28;
                        text-transform: uppercase;
                        color: #fff;
                        padding: 10px 25px;
                        text-align: center;
                        border-radius: 2px;
                        letter-spacing: 1px;
                        font-weight: bold;
                        text-shadow: 0em 0.1em 0.1em rgba(0,0,0,0.4);
                        font-size: 15px!important;
                    }
                }
            }
            
        }
    }
}
.home-quote_wrapper{
    .item-info{
        .input-form{
            >div{
                >div{
                    input{
                        &::placeholder{
                            text-align: left !important;
                        }
                    }
                }
            }
            .text-danger{
                padding: 0 !important;
                pre{
                    color: #ff0000b0!important;
                    max-width: 100%;
                    white-space: pre-wrap;
                    font-size: 12px;
                }
            }
        }
    }
}
.item-info{
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    margin-bottom: 15px;
    .input-form{
        >div{
            >div{
                input{
                    background: white;
                    border-radius: 4px;
                    padding: 3px 10px;
                    color: gray;
                    height: auto;
                    &::placeholder{
                        color: #bfbcbcbd;
                        font-weight: 300;
                        text-align: center;
                    }
                }
                i{
                    display: none;
                }
            }
        }
        .text-danger{
            background: transparent !important;
            padding: 0 !important;
            pre{
                background: transparent !important;
                color: red !important;
                text-align: left;
                max-width: 100%;
                white-space: pre-wrap;
                font-size: 12px;
            }
        }
    }
}

.text-box-credit{
    background: url('../../../assets//images/background-1300.jpg');
    // background-position: center;
    width: 250px;
    padding: 25px 15px;
    margin-bottom: 10px;
    p{
        font-family: auto;
        font-size: 1.1em;
        span{
            color: #3382d1;
            i{
                margin-right: 5px;
                font-size: .8em;
            }
        }
    }
}