/*
 * Component: Button
 * -----------------
 */
 body{
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid transparent;
        &.uppercase {
            text-transform: uppercase;
        }
        &.btn-flat {
            border-radius: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-width: 1px;
        }
        &:active {
            -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
        &:focus {
            outline: none;
        }
        &.btn-file {
            position: relative;
            overflow: hidden;
            > input[type='file'] {
                position: absolute;
                top: 0;
                right: 0;
                min-width: 100%;
                min-height: 100%;
                font-size: 100px;
                text-align: right;
                opacity: 0;
                filter: alpha(opacity = 0);
                outline: none;
                background: white;
                cursor: inherit;
                display: block;
            }
        }
    }

    .btn-default {
        background-color: #f4f4f4;
        color: #444;
        border-color: #ddd;
        &:hover, &:active, &.hover {
            background-color: #e7e7e7;
        }
    }

    .btn-primary {
        background-color: #3c8dbc;
        color: #fff;
        border-color: #367fa9;
        &:hover, &:active, &.hover {
            background-color: #367fa9;
        }
    }

    .btn-success {
        background-color: #00a65a;
        color: #fff;
        border-color: #008d4c;
        &:hover, &:active, &.hover {
            background-color: #008d4c;
        }
    }

    .btn-info {
        background-color: #00c0ef;
        color: #fff;
        border-color: #00acd6;
        &:hover, &:active, &.hover {
            background-color: #00acd6;
        }
    }

    .btn-danger {
        background-color: #dd4b39;
        color: #fff;
        border-color: #d73925;
        &:hover, &:active, &.hover {
            background-color: #d73925;
        }
    }

    .btn-warning {
        background-color: #f39c12;
        color: #fff;
        border-color: #e08e0b;
        &:hover, &:active, &.hover {
            background-color: #e08e0b;
        }
    }

    .btn-outline {
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        &:hover, &:focus, &:active {
            color: rgba(255, 255, 255, 0.7);
            border-color: rgba(255, 255, 255, 0.7);
        }
    }

    .btn-link {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .btn[class*='bg-']:hover {
        -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    }

    .btn-app {
        border-radius: 3px;
        position: relative;
        padding: 15px 5px;
        margin: 0 0 10px 10px;
        min-width: 80px;
        height: 60px;
        text-align: center;
        color: #666;
        border: 1px solid #ddd;
        background-color: #f4f4f4;
        font-size: 12px;
        > {
            .fa, .glyphicon, .ion {
                font-size: 20px;
                display: block;
            }
        }
        &:hover {
            background: #f4f4f4;
            color: #444;
            border-color: #aaa;
        }
        &:active, &:focus {
            -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
        > .badge {
            position: absolute;
            top: -3px;
            right: -10px;
            font-size: 10px;
            font-weight: 400;
        }
    }
}