//chat

// #root{
//   > div{
//     > div{
//       >div { 
//         &:nth-child(3){
//           .sc-chat-window{
//             width: 350px;
//             height: 450px;
            
//           }
//           > div{
//             > div:nth-child(1){
//               background: purple !important;
//               color: white;
//             }
//             &:nth-child(3){
//               background: rebeccapurple;
//             }
//           }
//         }
//       }
//     }
//   }
// }





.custom-appbar{
  background: linear-gradient(rgb(72, 48, 97), rgb(59, 38, 82) 60%, #21142F) !important;
}

.mini-menu-content{
  position: fixed;
  right:-300px;
  top: -1px;
  z-index: 9999;
  height: 100vh;
  width: fit-content;
  background: #fff;
  padding-top: 0px;
  max-width: 300px;
  transition: all 0.3s ease-in-out;
}
.mini-menu-content.active{
  right:0px;
}
.icon-close{

  width: 25px;
  height: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: pointer;
  &:hover{
    color: white;
    background-image: linear-gradient(#483061, #3b2652 60%, #321f46);
    background-repeat: no-repeat;
  }
}
.custom-appbar{
    > div{
      padding: 0;
    }
    .custome-table{
      ul{
        background-image: none;
        background-repeat: no-repeat;
        .left{
          float: left;
          padding-left: 5%;
          padding-top: 10px;
        }
        .right{
          float: right;
          padding-top: 20px;
          padding-bottom: 5px;
          padding-right: 20px;      
          button{
            span{
              color: white;
            }
          }
        }
      }
      
    }
  
}


.content-dropdown{
  background: white;
  li{
      padding: 12px 0;
      
      a{
          display: flex;
          align-items: center;
          img{
              width: 30px;
              height: 30px;
          }
          p{
              font-size: 14px;
              padding-left: 10px;
              span{
                  display: block;
                  font-size: 12px;
              }
          }
      }
  }
}
.title-menu{
  box-shadow: none !important;
  margin: 0px !important;
  min-height: 50px;
  border-top: 1px solid #ccc !important;

  > div {
      &:hover{
          background-image: linear-gradient(#483061, #3b2652 60%, #321f46);
          background-repeat: no-repeat;
          color: #fff;
          a{
                  color: #fff;
          }
      }
      &:nth-child(1){
          min-height: 50px !important;
          height: 50px !important;
      }
      > div{
          margin: 0px !important;
         a{
             i{
                 font-size: 15px;
                 margin-right: 3px;
                 color: gray;
             }
         }
      }
  }
}
.details{
  background: #fff;
}


