.detail{
    margin-top: 97px;
    @media(min-width: 992px){
        margin-top: 57px; 
    }
    .banner-detail{
        position: relative;
        min-height: 70vh;
        max-height: 70vh;
        overflow: hidden;
        z-index: 0;
        &::before{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            content: "";
            opacity: .1;
            z-index: 1;
           
        }
        .content-banner{
            position: absolute;
            z-index: 2;
            color: white;
            width: 100%;
            bottom: 0;
            .ct-bn-c{
                max-width: 68ch;
                margin: auto;
                padding: 2.4em 0;
                text-align: center;
                
                .title-b{
                    margin: .2em 0;
                    font-size: 25px;
                } 
                p{
                    font-size: 40px;
                }
                @media (max-width: 576px)
                {
                    .title-b {
                        font-size: 18px;
                    }
                    p{
                        font-size: 25px;
                    }
                       
                }
                .btn-buynow{
                    margin-top: 30px;
                    background: #37bef0;
                    color: white;
                    width: 130px;
                    border-radius: inherit;
                }
            }
        }
    }
    .content-detail{
        .ct-detail{
            .title-content{
                background-color: #f8f8f8;
                border-bottom: 1px solid #e5e5e5;
                padding: 0 1.625rem;
                margin: auto;
                // display: block;
                // width: 100%;
                // height: 78px;
                // top: 97px;
                // transition: height .2s linear;
                // z-index: 110;
                @media(max-width: 1300px){
                    top: 117px;
                }
                .ct-title-t{
                    display: flex;
                    align-items: center;
                    max-width: 1200px;
                    margin: auto;
                }
                .title-left{
                    width: 160px;
                    text-align: left;
                    padding: 1em;
                    font-size: 20px;
                    @media (max-width: 992px){
                        font-size: 18px;
                        margin-left: 0 !important;
                    }
                    @media (max-width: 576px){
                        font-size: 15px;
                    }
                }
                .title-right{
                    padding: 1em;
                    width: calc(100% - 160px);
                    @media (max-width: 992px){
                    margin-right: 0 !important;
                    }
                    .content-title-r{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .shop-product{
                            margin-right: .8rem;
                            text-align: right;
                            color: gray;
                            font-size: 15px;
                            @media (max-width: 992px){
                                font-size: 13px;
                            }
                            .shop-price{
                                display: block;
                                color: black;
                            }
                        }
                        .btn-shop{
                            padding: 15px;
                            border: 0;
                            font-size: 13px;
                            background: #30881c;
                            border-radius: inherit;
                            line-height: 1;
                            color: white;
                            text-align: center;
                            text-decoration: none;
                        }
                    }
                }
            }
            .landing-page{
                background-color: rgb(246, 246, 246);
                padding: 3.8em 0;
                .ct-c-landing-page{
                    max-width: 1200px;
                    margin: auto;
                    .title-highlights-container{
                        width: 100%;
                        max-width: 80vw;
                        margin: auto;
                        h4{
                            text-align: center;
                            letter-spacing: .125px;
                            font-size: 20px;
                            font-weight: 400;
                        }
                    }
                    .contant-highlights-container{
                        margin-top: 2.4em;
                    }
                }
            }
            .high-impact{
                height: auto;
                min-height: 600px;
                margin-top: 3em;
                .ct-high-impact{
                    max-width: 1200px;
                    margin: auto;
                    margin-bottom: 3em;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    .l-hight-impact{
                        display: inline-block;
                        width: 80%;
                        margin: auto;
                        .box-ct-hight{
                            padding: 3.4em 2em;
                            .high-impact-item{
                                span{
                                    font-size: 13px;
                                    font-weight: 500;
                                    letter-spacing: .02857rem;
                                    line-height: 1.42857rem;
                                    color: black;
                                }
                                h2{
                                    font-size: 35px;
                                    padding: 35px 0;
                                }
                            }
                            .high-impact-item-control{
                                .btn-hight{
                                    border: 1px solid #e5e5e5;
                                    background-color: #fdfdfd;
                                    color: #000;
                                    font-size: 11px !important;
                                    padding: 15px 30px;
                                    border-radius: inherit; 
                                    font-weight: 600;
                                }
                                .high-impact-control{
                                    color: gray;
                                    margin-top: 15px;
                                    span{
                                        display: block;
                                        font-size: 15px;
                                        padding-bottom: 5px;
                                    }
                                }
                            }
                            .list-info-hight{
                                margin-top: 3.4em;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .hight-item{
                                    width: 8em;
                                    height: 8em;
                                    img{
                                        max-width: 2.5em;
                                    }
                                    p{
                                        font-weight: 400;
                                        color: gray;
                                        letter-spacing: 1px;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        
                    }
                }
               
            }
            .image-portfolio{
                max-width: 1200px;
                margin: auto;
                padding-bottom: 30px;
                .title-image-po{
                    padding: 1.8em 1.625rem;
                    color: #262121;
                    text-align: center;
                    letter-spacing: 1.25px;
                    font-size: 35px;
                }
                .ct-image-portfolio{
                    display: flex;
                    .img-prot{
                        width: 768px;
                        height: auto;
                        margin-bottom: 15px;
                        .background-img-pr{
                            width: 100%;
                            height: auto;
                            display: inline-block;
                            @media (max-width: 576px){
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                    .content-a-image-portfolio{
                        max-width: 36%;
                        flex-basis: 36%;
                        margin: 0 2ch;
                        
                        .blur-image-portfolio{
                            h2{
                                margin-bottom: .8em;
                                font-size: 30px;
                                letter-spacing: 1.125px;
                            }
                            h4{
                                letter-spacing: 1.125px;
                                font-size: 17px;
                                margin-bottom: .7em;
                            }
                            p{
                                letter-spacing: 1.125px;
                                font-size: 13px;
                                margin-top: .4em;
                                margin-bottom: .8em;
                                color: gray;
                            }
                        }
                    }
                }
            }
            .landing-page-tell{
                background-color: rgb(246, 246, 246);
                width: 100%;
                padding: 4.8em 1.625rem;
                .ct-landing-page{
                    max-width: 1200px;
                    margin: auto;
                    h2{
                        font-size: 35px;
                        text-align: center;
                        letter-spacing: 1.25px;
                        margin-bottom: 15px;
                        span{
                            color: #25cc25;
                        }
                    }
                }
            }
        }
      
    }
}

.overview-highlight{
    margin: 1em 0;
    .ct-a-overview{
        display: flex;
        align-items: flex-start;
        img{
            @media (max-width: 576px){
                max-width: 50px;
            }
        }
        .ct-overview{
            padding-left: 2ch;
            .text-ct{
                margin-top: .8em;
                letter-spacing: 2.125px;
                color: #746e6e;
                font-size: 13px;
            }
        }
    }
   
}
.responsive-detail{
    margin-top: 55px;
    .banner-detail{
        @media (max-width: 576px){
            .content-banner{
                padding: 0 30px !important;
            }
        }
       
    }
    .content-detail{
        .ct-detail{
            @media (max-width: 576px){
                width: 100% !important;
            }
            
           .landing-page{
               .ct-c-landing-page{
                   .title-highlights-container{
                       h4{
                        font-weight: 300 !important;
                        letter-spacing: 2px !important;
                        font-size: 15px !important;
                       }
                   }
                   .contant-highlights-container{
                        @media (max-width: 576px){
                            margin: 0 15px;
                            .overview-highlight{
                                padding: 0;
                            }
                        }
                       .overview-highlight{
                           .ct-overview{
                               .text-ct{
                                letter-spacing: 1.125px;
                                font-size: 12px;
                               }
                           }
                       }
                   }
               }
           }
           .high-impact{
                padding: .5em 0 !important;
                height: auto !important;
               @media(max-width: 576px){
                .ct-high-impact{
                    position: relative;
                    z-index: 0;
                    margin-bottom: 1.5em;
                    &:before{
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #44b7c8;
                        content: "";
                        opacity: .1;
                        z-index: 1;
                    }
                    .l-hight-impact{
                        width: 100% !important;
                       
                        .box-ct-hight{
                            @media(max-width: 576px){
                                text-align: left !important;
                                width: 80% !important;
                            }
                            .high-impact-item{
                                span{
                                    font-size: 8px !important; 
                                    @media(max-width: 576px){
                                        font-size: 12px !important;
                                        font-weight: bold !important;
                                    }
                                }
                                h2{
                                    font-size: 20px !important;
                                    font-weight: 500 !important;
                                    letter-spacing: 1px !important;
                                }
                            }
                            .high-impact-item-control{
                                @media(max-width: 576px){
                                    width: 50% !important;
                                }
                                .btn-hight{
                                    background: #4276cd !important;
                                    color: white !important;
                                }
                                .high-impact-control{
                                    .high-impact-price{
                                        font-size: 15px !important;
                                    }
                                }
                            }
                            .list-info-hight{
                                display: inherit;
                                .hight-item{
                                    display: flex;
                                    align-items: center;
                                    text-align: left;
                                    margin-bottom: 1em;
                                    width: 100%;
                                    height: auto;
                                    img{
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
               }
           }
           .image-portfolio{
               .title-image-po{
                    font-size: 30px;
                    padding-top: .5em;
                    @media (max-width: 576px){
                        font-size: 25px;
                        padding-top: .1em !important;
                        padding: .8em 1.625rem !important;
                    }
               }
               .ct-image-portfolio{
                    display: inherit !important;
                   
                   .img-prot{
                        position: relative;
                        margin: 0 auto;
                        height: 450px;
                        width: auto;
                        @media(max-width: 576px){
                            height: 250px;
                        }
                        .background-img-pr{
                            position: absolute;
                            width: auto;
                            height: auto;
                            max-width: 100%;
                            max-height: 430px;
                            margin: auto;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            @media(max-width: 576px){
                                max-height: 230px;
                            }
                        }
                   }
                   .content-a-image-portfolio{
                        max-width: inherit;
                        flex-basis: auto;
                        
                        .blur-image-portfolio{
                            h2{
                                font-size: 22px;
                            }
                        }
                   }
               }
           }
           .landing-page-tell{
            @media (max-width: 576px){
                padding: 2.8em 1.625rem !important; 
            }
               .ct-landing-page{
                   h2{
                       font-size: 30px;
                       @media (max-width: 576px){
                           font-size: 15px;
                       }
                   }
               }
           }
        }
    }
}

#slide-landing-page{
    display: none;
    @media (max-width: 576px){
        display: block;
    }
    .overview-highlight{
        display: flex !important;
        align-items: flex-start !important;
        float: initial;
        padding: 3.6em 1.625rem;
        border: 1px solid #e5e5e5;
        background-color: #fdfdfd;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: inherit !important;
    }
}


